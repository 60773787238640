.searchInputs {
    display: flex;
}

.search {
    border-radius: 10px;
    border: 1px solid #DDD;
}

.search input {
    background-color: #f3f3f3;
    border: 0;
    font-size: 15px;
    padding: 15px;
    height: 20px;
    width: 175px;
}

.searchIcon {
    height: 40px;
    width: 40px;
    background-color: #f3f3f3;
    display: grid;
    place-items: center;
    border-radius: 2px;
    padding: 5px;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-top: 1px;
    width: 255px;
    height: 150px;
    background-color: #f3f3f3;
    overflow-y: auto;
}

.dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
}

.dataItem p {
    margin-left: 10px;
}

a {
    text-decoration: none;
}

#clearBtn {
    cursor: pointer;
}
