* {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
}

html {
    height: 100%;
    overflow-y: scroll;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.MuiButton-contained {
    background-color: #e0e0e0 !important;
    color: black !important;
    font-family: 'Poppins', sans-serif !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #666 !important;
}