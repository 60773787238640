
.navbar {
    background-color: white;
    height: 50px;
    display: flex;
    align-items: center;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 1.2rem;
    background: none;
    padding: 5px;
}

.nav-menu {
    padding-right: 15px;
    background-color: #f3f3f3;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 10000;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-overlay {
    width: 0vw;
    height: 0vh;
    position: absolute;
    transition-duration: 200ms;
    transition-property: background-color;
    left: 0px;
    top: 0px;
    z-index: 9999;
    background-color: #00000000;
}

.nav-overlay.active {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #00000055;
    transition-duration: 350ms;
    transition-property: background-color;
}

.nav-text {
    display: flex;
    align-items: center;
    padding: 8px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: black;
    font-size: 18px;
    width: 95%;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    margin-left: 10px;
    background-color: #00000003;
}

.nav-text a:hover {
    background-color: #489dff;
    transition: 200ms;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #f3f3f3;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
}

.nav-text span {
    margin-left: 16px;
}
