.accordion {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
}

.accordion__item + .accordion__item {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
	background-color: #f4f4f4;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
}

.accordion__button:hover {
	background-color: #ddd;
}

.accordion__button::before {
	display: inline-block;
	content: '';
	height: 10px;
	width: 10px;
	margin-right: 12px;
	border-bottom: 2px solid currentColor;
	border-right: 2px solid currentColor;
	animation-duration: 200ms;
	animation-fill-mode: both;
}

.accordion__button[aria-expanded='false']::before {
	animation-name: turn;
}

.accordion__button[aria-expanded='true']::before {
	animation-name: turnback;
}


[hidden] {
	display: none;
}

.accordion__panel {
	padding: 20px;
	padding-bottom: 0px;
}

.accordion__panel div {
	animation: fadein 0.35s;
}

@keyframes turn {
	from {transform: rotate(45deg);}
	to {transform: rotate(-45deg);}
}
@keyframes turnback {
	from {transform: rotate(-45deg);}
	to {transform: rotate(45deg);}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
