#searchInput {
  background-color: #f3f3f3;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 15px;
  padding: 8px;
  height: 20px;
  width: 300px;
}
.numberInput {
  background-color: #f3f3f3;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 15px;
  padding: 8px;
  height: 20px;
  width: 60px;
}
.searchBar {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
#pageSelector {
  margin-left: 30px;
  margin-right: 30px;
}
.MuiTableHead-root {
  position: sticky;
  top: 0;
  z-index: 10;
}
.table-text {
  margin: 10px;
}
.MuiDataGrid-menuIcon {
  margin-right: 0px !important;
}
@media (any-hover: none) {
  .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer {
    width: auto !important;
    visibility: visible !important;
  }
  .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
    width: auto !important;
    visibility: visible !important;
  }
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
    .MuiDataGrid-sortIcon {
    opacity: 0.5 !important;
  }
}
