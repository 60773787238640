.footer {
    margin-top: auto;
    border-top: 0.5px solid black;
    bottom: 0;
    padding-bottom: 10px;
}

.footer_text {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 0.8em;
    font-weight: 500;
}